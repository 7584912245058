.header {
    height: 56px;
    margin-bottom: 120px;
    background-color: var(--mantine-color-body);
    border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .inner {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .link {
    display: block;
    line-height: 1;
    padding: 8px 12px;
    border-radius: var(--mantine-radius-xl);
    text-decoration: none;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
  
    [data-mantine-color-scheme] &[data-active] {
      background-color: #7556a3;
      color: var(--mantine-color-white);
    }
  }