.card {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    /* padding: 40px; */
  }
  
  .title {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-weight: 700;
    font-size: 24px;
  }
  
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: var(--mantine-radius-md);
    height: 90px;
    /* background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7)); */
    background-color: #333;
    transition:
      box-shadow 150ms ease,
      transform 100ms ease;
  
    @mixin hover {
      box-shadow: var(--mantine-shadow-md);
      transform: scale(1.05);
    }
  }