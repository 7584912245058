.title {
    font-size: 34px;
    font-weight: 900;
  
    @media (max-width: $mantine-breakpoint-sm) {
      font-size: 24px;
    }
  }
  
  .description {
    /* max-width: 650px; */
    margin: auto;
  
    &::after {
      content: '';
      display: block;
      background-color: #7556a3;
      width: 45px;
      height: 2px;
      margin-top: var(--mantine-spacing-sm);
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .card {
    border: 1px solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
    padding: 30px;
    @media (max-width: 48em) {
      padding: 10px;
    }
  }
  
  .cardTitle {
    &::after {
      content: '';
      display: block;
      background-color: #7556a3;
      width: 45px;
      height: 2px;
      margin-top: var(--mantine-spacing-sm);
    }
  }
  .indicator {
    width: 12px;
    height: 4px;
    transition: width 250ms ease;
  
    &[data-active] {
      width: 40px;
    }
  }