.wrapper {
    padding-top: 20px;
    padding-bottom: 50px;
    text-align: left;
  }
  
  .item {
    display: flex;
    background-color: #333;
    padding: var(--mantine-spacing-lg);
    border-radius: var(--mantine-radius-md);
  }
  
  .itemIcon {
    /* background-color: #8469ad; */
    /* padding: var(--mantine-spacing-xs); */
    margin-right: var(--mantine-spacing-md);
  }
  
  .itemTitle {
    margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  }
  
  .supTitle {
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: var(--mantine-font-size-sm);
    color: #b2a1cc;
    letter-spacing: 0.5px;
  }
  
  .title {
    line-height: 1;
    text-align: center;
    margin-top: var(--mantine-spacing-xl);
  }
  
  .description {
    text-align: center;
    margin-top: var(--mantine-spacing-xs);
  }
  
  .highlight {
    background-color: #8469ad;
    padding: 5px;
    border-radius: var(--mantine-radius-sm);
    display: inline-block;
    color: light-dark(inherit, var(--mantine-color-white));
  }