.card {
    padding-top: 34px;
    padding-bottom: 52px;
}

.section-title {
    text-align: center;
    margin-bottom: 12px;
    font-size: 2.5rem;
    font-weight: 700;
}

.industry-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.industry-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.card-image-section {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 12px 24px;
}

.card-title {
    font-weight: 700;
    font-size: 1.25rem;
    /* margin-top: 12px; */
    /* margin-bottom: 12px; */
}

.card-list {
    list-style-type: disc;
    padding-left: 20px;
}

.card-list li {
    margin-bottom: 8px;
}

@media (max-width: 768px) {
    .card {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    .section-title {
        font-size: 2rem;
        margin-bottom: 32px;
    }
}