.wrapper {
  position: relative;
  padding-top: 150px;
  padding-bottom: 200px;
  background-size: cover;
  background-position: center;
  height: 55vh;

  @media (max-width: 48em) {
    padding-top: 80px;
    padding-bottom: 50px;
    height: auto;
    min-height: 60vh;
  }
}

.inner {
  padding-top: 40px;
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 48em) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

.title {
  font-weight: 800;
  font-size: 40px;
  letter-spacing: -1px;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  color: var(--mantine-color-white);
  margin-bottom: var(--mantine-spacing-xs);
  text-align: center;
  font-family: Greycliff CF, var(--mantine-font-family);

  @media (max-width: 48em) {
    font-size: 24px;
    text-align: center;
    line-height: 1.3;
  }
}

.description {
  color: var(--mantine-color-gray-0);
  text-align: center;

  @media (max-width: 48em) {
    font-size: var(--mantine-font-size-sm);
    text-align: center;
    padding-left: var(--mantine-spacing-xs);
    padding-right: var(--mantine-spacing-xs);
  }
}

.controls {
  margin-top: calc(var(--mantine-spacing-xl) * 1);
  display: flex;
  justify-content: center;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);

  @media (max-width: 48em) {
    flex-direction: column;
    align-items: center;
    margin-top: var(--mantine-spacing-md);
  }
}

.control {
  height: 42px;
  font-size: var(--mantine-font-size-md);

  &:not(:first-of-type) {
    margin-left: var(--mantine-spacing-md);
  }

  @media (max-width: 48em) {
    width: 80%;
    max-width: 250px;

    &:not(:first-of-type) {
      margin-top: var(--mantine-spacing-md);
      margin-left: 0;
    }
  }
}

  
  .secondaryControl {
    color: var(--mantine-color-white);
    background-color: rgba(255, 255, 255, 0.4);
  
    @mixin hover {
      background-color: rgba(255, 255, 255, 0.45);
    }
  }